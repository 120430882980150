@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Futura LT Pro';
  src: url('futura_lt_medium.woff') format('woff') /* Modern Browsers */
}

body {
  font-family: 'Futura LT Pro', sans-serif;
}

.container {
  max-width: 768px !important;
  min-height: calc(100vh - 115px - 190px);
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 30px 0 50px 0 ;
}

.form h2 {
  margin-top: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group input, .form-group select {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #666666;
  border-radius: 0;
  display: block;
  width: 100%;
}

.form-group input::placeholder, .form-group select {
  font-size: 12px;
}

.form-group label {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 12px;
}

.form-group select {
  text-transform: uppercase;
  background-color: transparent;
}
@media (min-width: 768px) {
  .form-group select {
    background-color: #DDDDDD;
  }
}
.form-group select:focus {
  background-color: transparent;
  outline: none;
}
.form-group select option {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .form-group .relative select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-group .relative::after {
    content: '+';
    position: absolute;
    right: 1rem;
    color: #000000;
    font-size: 1.5rem;
    line-height: 1.5rem;
    top: 6px;
    font-weight: 100;
  }
}

.checkbox-container label {
  display: block;
  margin-bottom: 0.5rem;
}